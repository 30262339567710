@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
  background-color: #151d23;
}

.chat-window {
  width: 60%;
  height: 100%;
  overflow-y: auto;
  /* Hide the scroll bar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.chat-window::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}


.user-message {
  background-color: #151d23;
  padding: 18px;
  margin-bottom: 0px;
  border-radius: 0px;
  align-self: flex-end;
  text-align-last: right;
  font-family: 'Courier New', Courier, monospace;
  color: #c8e6c9;
}

.bot-message {
  background-color: #13191e;
  padding: 18px;
  margin-bottom: 0px;
  border-radius: 0px;
  align-self: flex-start;
  font-family: 'Courier New', Courier, monospace;
  color: #c8d6e6;

}

.Title-Container {
  text-align: center;
  padding: 20px;
  display:flex;
}

.Title {
  color: #ffffff;
  letter-spacing: 2px;
  margin-bottom: 10px;
  font-family: "Kode Mono", monospace;
}
.Title-Image {
  background-image: url('../public/lowreg.png');
  width: 6em; /* Adjust as needed */
  height: 6em; /* Adjust as needed */
  background-size: cover;
  background-position: center;
  margin-right: 2em;
  margin-top: -10px;
}

.ChatInput {
  margin-top: 30px;
  display: flex;
  align-items: center;
  bottom: 20px;
  width: 60%;
  box-sizing: border-box;
  padding: 10px;
  background-color: #161c21;
}

input {
  padding: 12px;
  flex-grow: 1;
  margin-right: 8px;
  background-color: #1a2127;
  color:#ffffff;
  font-size: medium;
}

button {
  color: white;
  border: none;
  cursor: pointer;
}
.sendbtn {
  background-color: #094625;
  padding: 15px;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

.ButtonContainer {
  position: fixed;
  top: 50px;
  width: 90%;
  display: flex;
  justify-content: right;
  align-content: center;
  padding: 0px;
  margin: 0px;
}

.BottomLeftButton,
.BottomRightButton {
  position: inherit;
  display:flex;
  padding: 0.5em;
  border-radius: 10px;
  padding-right: 2em;
  padding-top: 0.8em;
  background-color: #24323c;
  color: white;
  border: none;
  cursor: pointer;
  text-decoration: none;

}
.BottomLeftButton{
  bottom: 30px
}

.BottomLeftButton:hover, .BottomRightButton:hover{
  background-color: #3e6581;
}
.img_git, .img_download {
  background-image: url('../public/github.png');
  width: 2em; /* Adjust as needed */
  height: 2em; /* Adjust as needed */
  background-size: cover;
  background-position: center;
  margin-right: 1em;
  margin-top: -0.2em;
}
.img_download{
  background-image: url('../public/download.png');
}

@media only screen and (max-width: 600px) {
  .ButtonContainer {
    position: relative; /* Remove fixed positioning */
    width: 100%; /* Adjust width */
    justify-content: space-between; /* Adjust alignment */
    align-items: center; /* Adjust alignment */
    margin-top: 20px; /* Add margin to separate from chat input */
    bottom: auto;
  }

  .BottomLeftButton {
    position: relative; /* Remove fixed positioning */
    bottom: auto; /* Remove bottom positioning */
    
    order: 1; /* Change the order to display after the chat input */
  }
}
